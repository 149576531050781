<template>
  <div class="container">
    <!--  <van-nav-bar
      title="活动详情"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    /> -->
    <div class="activityImg">
      <img :src="info.des_image" alt="" />
      <div class="xinyitip" v-if="info.has_fllow == 1">
        <img class="xin_yi" src="../assets/img/xinyi02.png" alt="" /><span
          >你心仪的TA也报名了!</span
        >
      </div>
      <div class="mask"></div>
    </div>
    <div class="activityInfo">
      <div class="activename">
        <span class="num">{{ info.num_limit }}人</span>
        <span class="txt">{{ info.title }}</span
        ><!-- van-ellipsis -->
      </div>
      <div class="overtime" v-if="info.app_left_time">
        <div>距离{{ info.activity_status == 1 ? "预约" : "报名" }}结束</div>
        <van-count-down :time="info.app_left_time * 1000">
          <template #default="timeData"
            ><span class="haisheng">还剩</span>
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
          </template>
        </van-count-down>
      </div>
    </div>
    <div class="activityTime">
      <div class="first">
        <div class="circle"></div>
        <div class="addressTime">活动时间及地点</div>
      </div>
      <div class="info-time">
        <img src="@/assets/img/time02.png" alt="" />
        <div>{{ info.activity_time }}</div>
      </div>
      <div class="info-time">
        <img src="../assets/img/address02.png" alt="" />
        <div>{{ info.event_venue }}</div>
      </div>
      <div class="second">
        <div class="circle"></div>
        <div class="addressTime">温馨提示</div>
      </div>
      <p>活动入场需<span class="tip">携带身份证证件及活动券码</span></p>
      <p>每场活动每人仅可购买一次，一次仅可一人使用</p>
      <p>活动结束劵码立即失效，请准时参加活动</p>
      <p class="dandu">
        报名后如需退款，请提前申请退款，活动开始前24小时之内不支持退款
      </p>
    </div>
    <div class="activityDetail">
      <h2>活动详情</h2>
      <div class="cont" v-html="content"></div>
    </div>
    <div class="zhanwei"></div>
    <div class="bottom-btn" v-if="info.status < 6">
      <div class="price">
        ￥<span class="vipprice">{{ info.vip_entrance_fee }}</span
        >/人
        <span class="oldprice">￥{{ info.entrance_fee }}/人</span>
      </div>
      <div class="btn" v-if="info.status == 1" @click="yuyue">预约</div>
      <div class="graybtn" v-if="info.status == 2">已预约</div>
      <div class="btn" v-if="info.status == 3" @click="submit">立即报名</div>
      <div class="graybtn" v-if="info.status == 4">已报名</div>
      <div class="graybtn" v-if="info.status == 5">已满员</div>
    </div>
  </div>
</template>

<script>
import { activity_info, sign_up } from "@/api/activity";
export default {
  components: {},
  data() {
    return {
      active: 0,
      info: {},
      id: null,
      content: ""
    };
  },
  created() {
    this.id = this.$route.query.id;
    console.log(this.$route.query.id, 985211311);
    this.getDetail(this.$route.query.id);
  },
  methods: {
    // 预约
    async yuyue() {
      const res = await sign_up({ a_id: this.id });
      if (res.data.code == 200) {
        this.info.status = 2;
        this.$toast("预约成功");
      } else if (res.data.code == 80009) {
        this.$router.push("/personalData");
      } else {
        this.$toast(res.data.message);
      }
    },
    onClickLeft() {
      this.$router.back();
    },
    async getDetail(a_id) {
      const res = await activity_info({ a_id });
      console.log(res, "详情");
      if (res.data.code == 200) {
        this.info = res.data.data;
        this.content = res.data.data.content;
      } else {
        this.$toast(res.data.message);
        this.$router.back();
      }
    },
    submit() {
      if (this.info.has_description == 0) {
        this.$router.push("/personalData");
      } else {
        this.$router.push({ path: "/confirmPay", query: { id: this.id } });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-icon-arrow-left::before {
  color: #323233;
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #f45b52;
}
.block {
  display: inline-block;
  width: 35px;
  color: #fff;
  font-size: 25px;
  text-align: center;
  background-color: #f45b52;
  border-radius: 7px;
}
/deep/.van-count-down {
  display: flex;
  align-items: center;
}
.container {
  background-color: #f2f2f2;
  //min-height: 100vh;
  .activityImg {
    width: 100%;
    height: 389px;
    position: relative;
    img {
      width: 100%;
      height: 389px;
    }
    .xinyitip {
      position: absolute;
      top: 34px;
      left: 24px;
      display: flex;
      align-items: center;
      width: 322px;
      height: 47px;
      background: #000000;
      opacity: 0.6;
      border-radius: 24px 24px 24px 24px;
      .xin_yi {
        width: 47px;
        height: 47px;
      }
      span {
        color: #ffffff;
      }
    }
    .mask {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 69px;
      background: linear-gradient(
        0deg,
        #f5f6fa 0%,
        rgba(249, 244, 244, 0) 100%
      );
    }
  }
  .activityInfo {
    width: 708px;
    //  height: 353px;
    background-color: #fff;
    margin: 0px auto 21px;
    border-radius: 14px;
    padding: 56px 28px;
    box-sizing: border-box;
    z-index: 999;
    .activename {
      //  display: flex;
      line-height: 55px;
      .num {
        display: inline-block;
        text-align: center;
        min-width: 90px;
        background: #f45b52;
        border-radius: 7px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 29px;
        margin-right: 21px;
      }
      .txt {
        font-size: 36px;
        color: #000000;
        line-height: 42px;
        flex-wrap: wrap;
        //  max-width: 527px;
      }
    }
    .overtime {
      width: 653px;
      height: 76px;
      background: #ffeeef;
      border-radius: 7px;
      padding: 0 22px;
      box-sizing: border-box;
      color: #f45b52;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 37px;
      .haisheng {
        color: #f45b52;
        margin: 0 8px;
      }
    }
  }
  .activityTime {
    width: 708px;
    // height: 599px;
    background-color: #fff;
    margin: 0 auto 21px;
    border-radius: 14px;
    padding: 56px 38px 40px;
    box-sizing: border-box;
    .first {
      display: flex;
      align-items: center;
      margin-bottom: 45px;
      .circle {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 5px solid #000000;
        border-radius: 50%;
        margin-right: 12px;
      }
      .addressTime {
        font-size: 35px;
        color: #333333;
        font-weight: bold;
      }
    }
    .info-time {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      img {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }
    .second {
      display: flex;
      align-items: center;
      margin-top: 69px;
      margin-bottom: 40px;
      .circle {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border: 5px solid #000000;
        border-radius: 50%;
        margin-right: 12px;
      }
      .addressTime {
        font-size: 35px;
        color: #333333;
        font-weight: bold;
      }
    }
    p {
      margin-left: 30px;
      color: #333333;
      //line-height: 49px;
      .tip {
        color: #fd2d39;
      }
    }
    .dandu {
      line-height: 49px;
    }
  }
  .activityDetail {
    width: 708px;
    // height: 1000px;
    background-color: #fff;
    border-radius: 14px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 50px 17px;
    h2 {
      text-align: center;
    }
    .cont >>> img {
      width: 664px !important;
      height: 100%;
    }
  }
  .zhanwei {
    width: 1px;
    height: 180px;
  }
  .bottom-btn {
    width: 100%;
    height: 139px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    .price {
      color: #fd2d39;
      .vipprice {
        font-size: 53px;
        font-weight: 500;
      }
      .oldprice {
        font-size: 28px;

        text-decoration: line-through;
        color: #bdbdbd;
      }
    }
    .btn {
      width: 247px;
      height: 90px;
      background: linear-gradient(-30deg, #fee151 1%, #ee7f31 99%);
      border-radius: 45px;
      text-align: center;
      line-height: 90px;
      font-size: 38px;
      font-weight: 600;
      color: #ffffff;
    }
    .graybtn {
      width: 247px;
      height: 90px;
      background: #eeeeee;
      border-radius: 45px;
      text-align: center;
      line-height: 90px;
      font-size: 38px;
      font-weight: 600;
      color: #999999;
    }
  }
}
</style>
